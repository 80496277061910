<template>
  <v-app>
    <HeaderSection />
    <VideoSection />
    <HowItWorksSection />
    <FranchiseComparisonSection />
    <AdvantageSection />
    <FinancialControlSection />
    <TestimonialsSection />
    <ColmeiaSection />
    <FooterSection />
  </v-app>
</template>

<script>
import HeaderSection from '@/components/HeaderSection.vue';
import VideoSection from '@/components/VideoSection.vue';
import HowItWorksSection from '@/components/HowItWorksSection.vue';
import FranchiseComparisonSection from '@/components/FranchiseComparisonSection.vue';
import AdvantageSection from '@/components/AdvantageSection.vue';
import FinancialControlSection from '@/components/FinancialControlSection.vue';
import TestimonialsSection from '@/components/TestimonialsSection.vue';
import ColmeiaSection from '@/components/ColmeiaSection.vue';
import FooterSection from '@/components/FooterSection.vue';
import axios from 'axios'

export default {
  name: 'LandingPage',
  components: {
    HeaderSection,
    VideoSection,
    HowItWorksSection,
    FranchiseComparisonSection,
    AdvantageSection,
    FinancialControlSection,
    TestimonialsSection,
    ColmeiaSection,
    FooterSection
  },
  mounted() {
      this.insertLog()
    },
    methods: {
      async sendMessage(message) {
        var options = {
          method: 'POST',
          url: 'https://discord.com/api/webhooks/1304247650874298459/oFdqlVMxPPR6b4Snj02VwV0BeO6gEipha1S5ciMtN5datnGbSHBzX0LwWrYGXFFqS2IL',
          data: {content: message}
        };

        try {
          let response = await axios.request(options);
          console.log(response.data);
        } catch (error) {
          console.error(error);
        }
      },
      async insertLog(){

          let response = await axios.get("https://ipgeolocation.abstractapi.com/v1/?api_key=4afbf62ff3024a9f9e8e3e22ef334955")
          let ipdata = response.data

          let log = {
            url: window.location.href,
            path: window.location.pathname,
            cidade: ipdata.city,
            estado: ipdata.region,
            ip: ipdata.ip_address,
            user_agent: navigator.userAgent,
            refferer: document.referrer,
            localstorage: JSON.stringify(localStorage, null, 2)
          }

          let anteswpp = "🔻".repeat(12)

          await this.sendMessage(anteswpp)

          let whatsappMessage = `*🌐 Nova visita no site da Beeasy!*\n`
                              + `URL: ${log.url}`
                              + `\nPath: ${log.path}`
                              + `\nCidade: ${log.cidade}`
                              + `\nEstado: ${log.estado}`
                              + `\nIP: ${log.ip}`
                              + `\nUser Agent: ${log.user_agent}`
                              + `\nRefferer: ${log.refferer}`

          await this.sendMessage(whatsappMessage)
          if(JSON.stringify(localStorage) != '{}'){
            let localstorage = "```" + JSON.stringify(localStorage) + "```"
            await this.sendMessage(localstorage)
          }
          
          let separatorWpp = "🔺".repeat(12)

          await this.sendMessage(separatorWpp)

          localStorage.setItem('log', 'true')

        }
    }
};
</script>

<style scoped>
@import '@/assets/global.css';
</style>
